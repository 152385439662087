<template>
    <div class="common">
        <div class="commonTop flex flex-ai-c flex-jc-sb">
            <div class="name flex flex-ai-c">{{$root.userInfo.ext_company_name}}{{$config.websiteTitle}}</div>
            <div class="" style="margin-right:auto;margin-left:20px">
                {{$root.userInfo.ext_time.date}} {{$root.userInfo.ext_time.week_no}}
            </div>
            <div :style="{color:$root.userInfo.ext_company.ext_deadline_left_days<30?'Wheat':''}" style="margin-left:auto;margin-right:50px;cursor:pointer;" @click="goMana" v-if="Number($root.userInfo.admin_is)==1">
                <i class="el-icon-warning"></i>
                到期时间：{{$root.userInfo.ext_company_deadline_date}}（剩余{{$root.userInfo.ext_company.ext_deadline_left_days}}天）
            </div>
            <div class="" style="margin-right:50px;cursor:pointer;" v-if="Number($root.userInfo.ext_son_count)>0" @click="gooverview()">
                <i class="el-icon-s-platform" style="margin-right:4px;"></i>工作概况
            </div>
            <div class="userbox flex flex-ai-c flex-jc-sb">
                <el-dropdown trigger="hover" class="msg" style="cursor: pointer;margin-right:50px">
                    <span class="el-dropdown-link">
                        <i class="el-icon-question"></i>
                        <span style="margin-left:5px">客户服务</span>
                        <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <div class="flex flex-column flex-ai-c flex-jc-sa">
                            <p style="padding:0 10px;text-align:center;line-height:1.5;color:#333;font-family:微软雅黑;">
                                客服电话：{{$root.userInfo.service.mobile}}<br>
                                或者扫描下方二维码直达客服微信：
                            </p>
                            <img :src="this.domain+$root.userInfo.service.qrcode" style="width: 200px;padding: 10px;box-sizing: border-box;">
                        </div>
                    </el-dropdown-menu>
                </el-dropdown>
                <!--时间搜索-->
                <!-- <el-select v-model="search" filterable size="mini"  placeholder="请选择时间" class="commonSearch">
                <el-option v-for="item in searchList" :key="item" :label="item" :value="item"></el-option>
            </el-select> -->

                <!-- <el-row class="msg flex flex-ai-c" @click="goMsg()">
                <p>消息通知</p>
                <el-badge :value="$root.count.n_count==0?'':$root.count.n_count" class="item">
                    <img src="../assets/laba2.png" alt="">
                </el-badge>
            </el-row> -->
                <div class="user" id="muser">
                    <el-dropdown trigger="click">
                        <span class="el-dropdown-link flex flex-ai-c">
                            {{$root.userInfo.realname}}<i class="el-icon-arrow-down" style="margin-left:5px"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item v-if="$root.userInfo.admin_is==1" icon="el-icon-monitor" @click.native="goover()">进入后台</el-dropdown-item>
                            <el-dropdown-item icon="el-icon-s-unfold" @click.native="logOut()">退出登录</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
        </div>

        <div class="commonLeft" :style="{'width':isCollapse?'64px':'150px'}">
            <div class="nav">
                <el-aside>
                    <el-menu background-color="#191A23" :default-active="defaultActive" text-color="rgba(255, 255, 255, 0.7)" :default-openeds="[navList.length.toString()]" active-text-color="#fff" :collapse="isCollapse" style="height:calc( 100% - 145px )">
                        <template v-for="nav in navList">
                            <el-menu-item :index="nav.index" :key="nav.name" v-if="(!nav.sub) && nav.isShow" @click="jumpPage(nav)">
                                <i :class="nav.icon"></i>
                                <span slot="title">
                                    {{nav.name }}
                                </span>
                            </el-menu-item>
                            <el-submenu :index="nav.index" :key="nav.name" v-if="nav.sub && nav.isShow">
                                <template slot="title">
                                    <i :class="nav.icon" v-if="nav.icon"></i>
                                    <span> {{nav.name}} </span>
                                </template>
                                <template v-for="(item,sIndex) in nav.sub">
                                    <el-menu-item :index="item.index" :key="item.index" v-if="!item.sub" @click="jumpPage(item)">
                                        <i :class="item.icon" v-if="item.icon"></i>
                                        <span style="font-size:13px;">{{item.name}}</span>
                                    </el-menu-item>
                                </template>
                            </el-submenu>
                        </template>
                        <p style="height:50px;background:#191A23"></p>
                    </el-menu>

                    <div class="menuBtnbox" :style="{'width':isCollapse?'64px':'150px'}">
                        <i class="el-icon-s-operation menuBtn" @click="handleCollapse()"></i>
                    </div>
                </el-aside>
            </div>
        </div>
        <div class="commonRight" :style="{'left':isCollapse?'64px':'150px'}">
            <router-view :isCollapse="isCollapse" />
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import driverStep from "@/plugins/driver"
export default {
    data: function () {
        return {
            count: '', //消息通知数量
            search: '',
            defaultActive: '1',
            searchList: ["三个月", "一年内", "全部"], //下拉时间列表

            isCollapse: false,

            navList: [{
                name: '工作汇报',
                path: '/overview',
                icon: 'el-icon-s-home',
                isShow: true,
                index: '1'
            }, {
                name: '我的周报',
                path: '/week',
                icon: 'el-icon-edit-outline',
                isShow: true,
                index: '2'
            }, {
                name: '我的月报',
                path: '/month',
                icon: 'el-icon-date',
                isShow: true,
                index: '3'
            }, {
                name: '岗位职责',
                path: '/admin',
                icon: 'el-icon-menu',
                isShow: true,
                index: '4'
            }, {
                name: '我的下级',
                path: '',
                icon: 'el-icon-s-custom',
                isShow: false,
                index: '5'
            }],
        }
    },
    watch: {
        $route() {
            this.http.post('/admin.work/getUser').then(re => {
                this.dataFormat(re);
            })
        }
    },
    mounted() {
        this.state();
        // 在mounted生命周期或方法中执行下述代码
        // setTimeout(()=>{
        // 	if(this.$root.userInfo.intro_is==1){
        // 		this.$driver.defineSteps(driverStep)
        // 		this.$driver.start()
        // 	}
        // 	console.log('kk');
        // },500)
    },
    //导航守卫
    beforeRouteEnter(to, from, next) {
        // Promise.all 會等到數組內的 Promise 都 resolve 後才會繼續跑(then)
        let _token = window.sessionStorage.getItem('_token');
        Promise.all([
            axios.post(window.domain + '/Login/state', {
                id: sessionStorage.getItem('userId')
            }, {
                headers: {
                    'content-type': 'application/json',
                    'X-CSRF-TOKEN': _token
                }
            }),
            //下级人员
            axios.post(window.domain + '/admin.work/getUser', {}, {
                headers: {
                    'content-type': 'application/json',
                    'X-CSRF-TOKEN': _token
                }
            }),
        ]).then(result => next(vm => {
            if (result[0].data.code == 200) {
                vm.$root.userInfo = result[0].data.data;
            };
            if (result[1].data.code == 200) vm.dataFormat(result[1].data.data);
        }))
    },
    // updated(){
    //     console.log("updated")
    // 	this.http.post('/admin.work/getUser').then(re => {
    // 		this.dataFormat(re);
    // 		let ar = this.navList.filter(val=>{
    // 			return val.path==this.$route.path
    // 		})
    // 		this.defaultActive = ar[0].index;
    // 	})
    // },
    methods: {
        state() {
            this.http.post('/Login/state', {
                id: sessionStorage.getItem('userId')
            }).then(re => {
                if (Number(re.ext_company_deadline_over_is) == 1) {
                    if (Number(re.admin_is) == 1) {
                        this.$message.error('服务费已到期，正在前往缴费页面...');
                        setTimeout(() => {
                            this.$router.push('/payment');
                        }, 1500)
                    } else {
                        this.$message.error('平台已过期，请通知管理员续费');
                    }
                }
            })
        },
        gooverview() {
            let _token = window.sessionStorage.getItem('_token');
            // window.open('http://localhost:8082/?token=' + _token);
            window.open(this.domain + '/workOverview/dist/index.html?token=' + _token);
        },
        goMana() {
            this.$router.push('/payment');
            // var url = '/admin.company/rootLogout';
            // this.http.post(url).then(() => {
            // 	this.$router.push('/headquartersMerchant');
            // });
        },
        dataFormat: function (data) {
            let arr = [];
            this.isShowChild(data);
            data.forEach(item => {
                arr.push({
                    id: item.id,
                    name: item.uname,
                    path: '/week',
                    index: item.id.toString(),
                })
            })
            this.navList[this.navList.length - 1]['sub'] = arr;
            this.defaultRoute();
        },
        //是否展示下级
        isShowChild: function (data) {
            this.navList.forEach(item => {
                //判断周报月报是否显示
                // if(item.index == 2 || item.index == 3){
                //     this.$set(item,'isShow',this.$root.userInfo.parent_id > 0 ? true : false)
                // }
                //判断我的下级是否显示
                if (item.index == 5) {
                    this.$set(item, 'isShow', data.length > 0 ? true : false)
                }
            });
        },

        //刷新页面默认显示选中模块
        defaultRoute: function () {
            this.navList.forEach(item => {
                if ((this.$route.path == '/week' || this.$route.path == '/month') && this.$route.query.id) {
                    this.defaultActive = this.$route.query.id;
                } else if (this.$route.path == item.path) {
                    this.defaultActive = item.index;
                }
            })
        },

        jumpPage: function (nav) {
            let param = nav.id ? {
                id: nav.id
            } : {};
            this.$router.push({
                path: nav.path,
                query: param
            })
        },

        handleCollapse: function () {
            this.isCollapse = !this.isCollapse;
        },
        logOut: function () {
            this.http.post('/login/logout').then(() => {
                this.$router.push('/');
                sessionStorage.clear();
            })
        },
        //进入后台
        goover() {
            this.$router.push('/organization')
        }
    },
}
</script>
